import { Box, Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify'
import 'react-quill/dist/quill.snow.css';
import { modules } from '../components/moduleToolbar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import InputLabel from '@mui/material/InputLabel';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RefreshIcon from '@mui/icons-material/Refresh';
import PhotoIcon from '@mui/icons-material/Photo';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import GroupIcon from '@mui/icons-material/Group';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const validationSchema = yup.object({
    title: yup
        .string('Add a post title')
        .min(4, 'text content should havea minimum of 4 characters ')
        .required('Post title is required'),
    content: yup
        .string('Add text content')
        .min(10, 'text content should havea minimum of 10 characters ')
        .required('text content is required'),
});

const EditPost = () => {

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [tags, setTags] = useState([]);
    const [timetableInstagram, setTimetableInstagram] = useState([]);
    const [timetableTiktok, setTimetableTiktok] = useState([]);

    const [deepLink, setDeepLink] = useState('');
    const [title, setTitle] = useState('');
    const [imagecreationtext, setImagecreationtext] = useState('');
    const [content, setContent] = useState('');
    const [newTimetableDay, setNewTimetableDay] = useState('');
    const [refreshcontent, setRefreshContent] = useState(null);
    const [conclusion, setConclusion] = useState(null);
    const [newTag, setNewTag] = useState('');
    const [newTimetableTime, setNewTimetableTime] = useState(null);
    const [newTimetableTimeString, setNewTimetableTimeString] = useState('');
    const [newTimetableRepeat, setNewTimetableRepeat] = useState('');
    const [newTimetableDestination, setNewTimetableDestination] = useState('');

    let maxSteps = 5;

    const [activeStep, setActiveStep] = useState(0);
    const [groups, setGroups] = useState([]);
    const [image, setImage] = useState([]);
    const [newGroup, setNewGroup] = useState('');

    const setGroupsActive = (index) => (event) => {
        const newGroups = [...groups];
        newGroups[index].used = event.target.checked === true;
        setGroups(newGroups);
    };

    const setTimetableInstagramActive = (index) => (event) => {
        const instagramTimetable = [...timetableInstagram];
        instagramTimetable[index].used = event.target.checked === true;
        setTimetableInstagram(instagramTimetable);
    };

    const setTimetableTiktokActive = (index) => (event) => {
        const tiktokTimetable = [...timetableTiktok];
        tiktokTimetable[index].used = event.target.checked === true;
        setTimetableTiktok(tiktokTimetable);
    };

    const addCustomerGroup = () => {
        const exists = groups.filter(one => one.title === newGroup).length > 0;
        if (!exists) {
            const newGroups = [...groups];
            newGroups.push({
                title: newGroup,
                description: '',
                used: true
            });
            setGroups(newGroups);
            setNewGroup('');
        }
    };


    const handleChangeTimetableDay = (event) => {
        setNewTimetableDay(event.target.value);
    };

    const handleChangeNewTag = (event) => {
        setNewTag(event.target.value);
    };

    const addCustomeTag = (event) => {
        const newTagsFromInput = newTag.split(' ');

        newTagsFromInput.forEach(tag => {
            tags.push(`#${tag}`);
        });
        setNewTag('');
        setTags(tags);
    };

    const handleChangeTimetableTime = (value) => {
        setNewTimetableTime(value);
        console.log(value.hour())
        console.log(value.minute())
        setNewTimetableTimeString(`${value.hour()}:${value.minute()}`);
    };

    const handleChangeTimetableRepeat = (event) => {
        setNewTimetableRepeat(event.target.value);
    };

    const handleChangeTimetableDestination = (event) => {
        setNewTimetableDestination(event.target.value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [expanded, setExpanded] = useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleBackdropToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };

    const navigate = useNavigate();

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues: {
            title,
            content,
            deepLink,
            imagecreationtext,
            refreshcontent,
            image,
            tags,
            timetableInstagram,
            timetableTiktok
        },

        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, actions) => {
            updatePost(values);
            //alert(JSON.stringify(values, null, 2));
            //actions.resetForm();
        },
    });

    const createContent = async () => {
        try {
            handleBackdropToggle();
            const { data } = await axios.get(`/api/content/create/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                setTitle(data.content.title);
                setContent(data.content.text);
                setImagecreationtext(data.content.imagecreationtext);
                handleBackdropClose();
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    }

    const addTimetable = () => {
        if (newTimetableDestination === 'instagram') {
            const instagramTimetable = [...timetableInstagram];
            instagramTimetable.push({
                time: newTimetableTimeString,
                day: newTimetableDay,
                repeat: newTimetableRepeat,
                used: true
            });
            setTimetableInstagram(instagramTimetable);
        } else  {
            const tiktokTimetable = [...timetableTiktok];
            tiktokTimetable.push({
                time: newTimetableTimeString,
                day: newTimetableDay,
                repeat: newTimetableRepeat,
                used: true
            });
            setTimetableTiktok(tiktokTimetable);
        }
    };

    const createTags = async () => {
        try {
            handleBackdropToggle();
            const values = {content: product.data.content};
            const { data } = await axios.post(`/api/content/tags`, values);
            if (data.success === true) {
                toast.success(data.message);
                setTags(data.tags);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const refreshContent = async () => {
        try {
            handleBackdropToggle();
            const values = {content};
            const { data } = await axios.post(`/api/content/recreate`, values);
            if (data.success === true) {
                toast.success(data.message);
                setRefreshContent(data.content);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const handleChangeNewGroup = event => {
        const newGroupName = event.target.value;
        setNewGroup(newGroupName);
    }

    const createTimetable = async (destination) => {
        try {
            handleBackdropToggle();
            // we need the customer groups for the time table
            const values = {content: product.data.content, destination, groups: groups.filter(one => one.used).map(one => one.title)};

            const { data } = await axios.post(`/api/content/timetable`, values);

            if (data.success === true) {
                toast.success(data.message);
                if (destination === 'tiktok') {
                    setTimetableTiktok(data.timetable)
                } else {
                    setTimetableInstagram(data.timetable)
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const createGroups = async () => {
        try {
            handleBackdropToggle();
            const values = {content: product.data.content};
            const { data } = await axios.post(`/api/content/groups`, values);
            if (data.success === true) {
                toast.success(data.message);
                setGroups(data.groups);
                setConclusion(data.conclusion);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }


    const updateUsedImages = async (e, id) => {
        const newImaageList = image.map(element => {
            if (element.index == id) {
                element.active = !element.active;
            }
            return element;
        })
        setImage(newImaageList);
    };


    //show post by Id
    const getPostById = async () => {
        handleBackdropToggle();
        try {
            const { data } = await axios.get(`/api/post/${id}`);

            setProduct(data.post.product);
            setTitle(data.post.title);
            setContent(data.post.content);
            setDeepLink(data.post.product.data.link);

            setActiveStep(data.post.step);
            let images = {};
            if (data.post.product.data.images) {
                data.post.product.data.images.forEach((one, index) => {
                    images[one] = {
                        index,
                        img: one,
                        active: false
                    };
                });
            }

            let indexOfELement = Object.keys(images).length;
            if (data.post.image) {
                data.post.image.forEach(one => {
                    if (!images[one.url]) {
                        images[one.url] = {
                            index: indexOfELement++,
                            img: one.url,
                            active: one.used
                        };
                    }
                    images[one.url].active = one.used;
                });
            }

            setImage(Object.values(images));
            if (data.post.customergroup) {
                setGroups(data.post.customergroup);
            }

            if (data.post.timetable) {
                const ttinsta = data.post.timetable.filter(one => one.destination === 'instagram');
                const tttiktok = data.post.timetable.filter(one => one.destination === 'tiktok');
                setTimetableInstagram(ttinsta);
                setTimetableTiktok(tttiktok);
            }
            if (data.post.tags) {
                setTags(data.post.tags.split(' '));
            }

        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    useEffect(() => {
        getPostById();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updatePost = async (values) => {
        try {
            values.image = image.map(one => ({
                url: one.img, used: one.active == true
            }));
            const timetables = [];
            timetableInstagram.forEach(one => {
                timetables.push({...one, destination: 'instagram'})
            });
            timetableTiktok.forEach(one => {
                timetables.push({...one, destination: 'tiktok'})
            });
            values.tags = tags.join(' ');
            values.step = activeStep;
            values.customergroup = groups;
            values.timetable = timetables;
            const { data } = await axios.put(`/api/post/update/${id}`, values);
            if (data.success === true) {
                toast.success('post updated');
                navigate('/user/post/list');
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.error);
        }
    }


    const handleDelete = (tagToDelete) => () => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ bgcolor: "white", padding: "20px" }}>
                <Typography variant='h5' sx={{ pb: 4 }}> {t('post.edit.title')} </Typography>
            </Box>
            {product ?
            <Box sx={{ bgcolor: "white", padding: "20px" }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {t('post.edit.accordion.product.title')}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{product.data.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table sx={{ minWidth: 700 }} aria-label="product table">
                            <TableBody>
                                <TableRow key="title">
                                    <TableCell>{t('post.edit.accordion.product.title')}</TableCell>
                                    <TableCell>{product.title}</TableCell>
                                </TableRow>
                                <TableRow key="articlenumber">
                                    <TableCell>{t('post.edit.accordion.product.artnumber')}</TableCell>
                                    <TableCell>{product.artnumber}</TableCell>
                                </TableRow>
                                <TableRow key="source">
                                    <TableCell>{t('post.edit.accordion.product.source')}</TableCell>
                                    <TableCell>{product.source}</TableCell>
                                </TableRow>
                                <TableRow key="language">
                                    <TableCell>{t('post.edit.accordion.product.language')}</TableCell>
                                    <TableCell>{product.language}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            </Box> : <></>}

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <Stepper activeStep={activeStep} orientation="vertical">

                    <Step key="step1">
                        <StepLabel>{t('post.edit.step.targetgroup.title')}</StepLabel>
                        <StepContent>
                            <Box sx={{ bgcolor: "white", padding: "20px" }}>
                                <Typography>{t('post.edit.step.targetgroup.description')}</Typography>
                                <Box sx={{ clear: 'both'}}>
                                    <Box sx={{ clear: 'both'}}>
                                        <Button sx={{float: "right"}}
                                            onClick={() => createGroups()}
                                            variant='contained'
                                            color="success"
                                            startIcon={<SystemUpdateAltIcon />}>{t('post.edit.targetgroup.button.title')}</Button>
                                    </Box>
                                    {conclusion ? <Box sx={{ pt: 3, clear: 'both'}}><h5>{conclusion}</h5></Box> : ''}
                                    <Box sx={{ clear: 'both'}}>
                                        <List>
                                            {groups.map((entry, index) => {
                                                const labelId = `checkbox-list-label-${index}`;
                                                const keyId = `group-${index}`;
                                                return (
                                                    <ListItem key={keyId}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                onChange={setGroupsActive(index)}
                                                                checked={entry.used === true}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                            <GroupIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={entry.title}
                                                            secondary={
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >{entry.description}</Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Box>
                                    <Box sx={{ py: 2, width: {md: '100%', lg: '30%'}, clear: 'both'}}>
                                    <TextField
                                        id="newGroup"
                                        value={newGroup}
                                        fullWidth
                                        onChange={handleChangeNewGroup}
                                        label={t('post.edit.step.targetgroup.input.label')}
                                        placeholder={t('post.edit.step.targetgroup.placeholder.label')}
                                        variant="standard" />
                                    <Button sx={{ mt: 2, mb:4}} onClick={() => addCustomerGroup()} variant='contained' startIcon={<AddCircleOutlineIcon />}>{t('post.edit.step.targetgroup.button.label')}</Button>
                                    </Box>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {t('post.edit.step.button.continue')}
                                    </Button>
                                    </div>
                                </Box>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key="step2">
                        <StepLabel>
                            {t('post.edit.step.timetable.title')}
                        </StepLabel>
                        <StepContent>
                            <Box sx={{ bgcolor: "white", padding: "20px" }}>
                                <Typography>{t('post.edit.step.timetable.description')}</Typography>
                                <Box sx={{ pb: 2, clear: 'both'}}>
                                    <Box sx={{ pb: 2}}>
                                        <h5>{t('post.edit.timetable.title')}</h5>
                                    </Box>
                                    <Box sx={{ pb: 2 }}>
                                        <Button
                                            onClick={() => createTimetable('instagram')}
                                            variant='contained'
                                            color="success"
                                            startIcon={<AccessAlarmIcon />}>{t('post.edit.timetable.instagram.button.title')}</Button>
                                        <Button sx={{ml: 2}}
                                            onClick={() => createTimetable('tiktok')}
                                            variant='contained'
                                            color="success"
                                            startIcon={<AccessAlarmIcon />}>{t('post.edit.timetable.tiktok.button.title')}</Button>
                                    </Box>
                                    <Box sx={{ pb: 2, width: {md: '50%', lg: '20%'}, float:'left'}}>

                                        <h4>{t('post.edit.timetable.instagram.title')}</h4>
                                        <List>
                                        {timetableInstagram.map((entry, index) => {
                                            const labelId = `checkbox-list-tt-insta-label-${index}`;
                                            const keyId = `timetable-instagram-${index}`;
                                            return (
                                                <ListItem key={keyId}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            onChange={setTimetableInstagramActive(index)}
                                                            checked={entry.used === true}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <AccessAlarmIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={entry.day}
                                                        secondary={
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >{entry.time} {entry.repeat}</Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                        </List>
                                    </Box>
                                    <Box sx={{ pb: 2, width: {md: '50%', lg: '20%'}, float:'left'}}>

                                        <h4>{t('post.edit.timetable.tiktok.title')}</h4>
                                        <List>
                                        {timetableTiktok.map((entry, index) => {
                                            const labelId = `checkbox-list-tt-tiktok-label-${index}`;
                                            const keyId = `timetable-tiktok-${index}`;
                                            return (
                                                <ListItem key={keyId}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            onChange={setTimetableTiktokActive(index)}
                                                            checked={entry.used === true}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <AccessAlarmIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={entry.day}
                                                        secondary={
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >{entry.time} {entry.repeat}</Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                        </List>
                                    </Box>
                                    <Box sx={{ py: 2,  clear: 'both', width: {md: '100%', lg: '30%'},}}>
                                        <FormControl sx={{ m: 1 }} fullWidth>
                                            <InputLabel id="new-timetable-day-label">{t('post.edit.timetable.new.day')}</InputLabel>
                                            <Select
                                                labelId="new-timetable-day-label"
                                                id="new-timetable-day"
                                                value={newTimetableDay}
                                                label={t('post.edit.timetable.new.day')}
                                                onChange={handleChangeTimetableDay}
                                                >
                                                <MenuItem value="monday">Montag</MenuItem>
                                                <MenuItem value="tuesday">Dienstag</MenuItem>
                                                <MenuItem value="wednesday">Mittwoch</MenuItem>
                                                <MenuItem value="thursday">Donnerstag</MenuItem>
                                                <MenuItem value="fryday">Freitag</MenuItem>
                                                <MenuItem value="saturday">Samstag</MenuItem>
                                                <MenuItem value="sunday">Sonntag</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ m: 1 }} fullWidth>
                                            <DemoItem label={`${t('post.edit.timetable.new.time')} (${newTimetableTimeString})`}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MultiSectionDigitalClock
                                                        ampm={false}
                                                        value={newTimetableTime}
                                                    onChange={(newValue) => handleChangeTimetableTime(newValue)} />
                                                </LocalizationProvider>
                                            </DemoItem>
                                        </FormControl>
                                        <FormControl sx={{ m: 1 }} fullWidth>
                                            <InputLabel id="new-timetable-repeat-label">{t('post.edit.timetable.new.repeat')}</InputLabel>
                                            <Select
                                                labelId="new-timetable-repeat-label"
                                                id="new-timetable-repeat"
                                                value={newTimetableRepeat}
                                                label={t('post.edit.timetable.new.repeat')}
                                                onChange={handleChangeTimetableRepeat}
                                                >
                                                <MenuItem value="none">none</MenuItem>
                                                <MenuItem value="dayly">24h</MenuItem>
                                                <MenuItem value="weekly">Wöchentlich</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ m: 1 }} fullWidth>
                                            <InputLabel id="new-timetable-dsestination-label">{t('post.edit.timetable.new.destination')}</InputLabel>
                                            <Select
                                                labelId="new-timetable-dsestination-label"
                                                id="new-timetable-dsestination"
                                                value={newTimetableDestination}
                                                label={t('post.edit.timetable.new.day')}
                                                onChange={handleChangeTimetableDestination}
                                                >
                                                <MenuItem value="instagram">Instagram</MenuItem>
                                                <MenuItem value="tiktok">TikTok</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Button sx={{float: "right", mt: 2, mb:4}} onClick={() => addTimetable()} variant='contained' startIcon={<AddCircleOutlineIcon />}>{t('post.edit.timetable.custome.title')}</Button>
                                    </Box>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {t('post.edit.step.button.continue')}
                                    </Button>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {t('post.edit.step.button.back')}
                                    </Button>
                                    </div>
                                </Box>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key="step3">
                        <StepLabel>
                        {t('post.edit.step.content.title')}
                        </StepLabel>
                        <StepContent>
                            <Box sx={{ bgcolor: "white", padding: "20px" }}>
                                <Typography>{t('post.edit.step.content.description')}</Typography>
                                <Typography variant='p' sx={{ pb: 4 }}> {t('post.edit.deeplink.label')}: {deepLink}  </Typography>

                                <Box sx={{ pb: 2, display: "flex", justifyContent: "right" }}>
                                    <Button onClick={() => createContent()} variant='contained' color="success" startIcon={<SystemUpdateAltIcon />}>Erstelle Kontent</Button>
                                </Box>
                                <Box sx={{ mt: 1 }}>

                                    <TextField sx={{ mb: 3 }}
                                        fullWidth
                                        id="title"
                                        label="Titel"
                                        name='title'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Titel"
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.title && Boolean(errors.title)}
                                        helperText={touched.title && errors.title}
                                    />


                                    <TextField sx={{ mb: 3 }}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        id="content"
                                        label="Beitrag Text"
                                        name='content'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Beitrag Text"
                                        value={values.content}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.content && Boolean(errors.content)}
                                        helperText={touched.content && errors.content}
                                    />



                                    <Box sx={{ pt:2, pb: 2, clear: 'both'}}></Box>
                                    <Button sx={{float: "right", mb:4}} onClick={() => refreshContent()} variant='contained' startIcon={<RefreshIcon />}>Verbessere</Button>

                                    {refreshcontent ? <TextField sx={{ mb: 3 }}
                                        multiline
                                        disabled
                                        rows={4}
                                        fullWidth
                                        id="refreshcontent"
                                        label="Verbesserter Text"
                                        name='refreshcontent'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Text"
                                        value={values.refreshcontent}
                                    />: <></>}
                                    <Box sx={{ pb: 2, clear: 'both'}}>
                                        <Button sx={{float: "right", mb: 2}} onClick={() => createTags()} variant='outlined' color="secondary"  startIcon={<PhotoIcon />}>Erzeuge weiteres Bild</Button>
                                        <TextField sx={{ mb: 2 }}
                                            multiline
                                            disabled
                                            rows={4}
                                            fullWidth
                                            id="imagecreationtext"
                                            label="Text zur Bildgenerierung"
                                            name='imagecreationtext'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder="Bilder Text"
                                            value={values.imagecreationtext}
                                        />
                                    </Box>
                                    <hr />
                                    <h2>{t('post.edit.step.content.tag.title')}</h2>
                                    <Box sx={{ pb: 2, clear: 'both'}}>
                                        <Button sx={{float: "right", mb: 2}} onClick={() => createTags()} variant='contained' color="success" startIcon={<SystemUpdateAltIcon />}>{t('post.edit.step.tag.button.create.label')}</Button>
                                        <input type="hidden" value={tags.join(' ')} name="tags" id="tags" />
                                        <Box>
                                            {tags.map((tag) => (
                                                <Chip sx={{ mb: 1, mr: 1 }} label={tag} variant="outlined" onDelete={handleDelete(tag)} />
                                            ))}
                                        </Box>
                                        <TextField
                                            id="newGroup"
                                            value={newTag}
                                            fullWidth
                                            onChange={handleChangeNewTag}
                                            label={t('post.edit.step.tag.input.label')}
                                            placeholder={t('post.edit.step.tag.placeholder.label')}
                                            variant="standard" />
                                        <Button sx={{ mt: 2, mb:4}} onClick={() => addCustomeTag()} variant='contained' startIcon={<AddCircleOutlineIcon />}>{t('post.edit.step.tag.button.label')}</Button>
                                    </Box>

                                    <hr/>

                                    <h2>{t('post.edit.step.content.images.title')}</h2>

                                    <Box sx={{ pb: 2, clear: 'both'}}>
                                        {image.length > 0  ? <hr/> : <></>}

                                        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                                            {image.map((item, index) => (
                                                <ImageListItem key={item.img}>
                                                <img
                                                    alt=""
                                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                    loading="lazy"
                                                />
                                                <ImageListItemBar
                                                    sx={{
                                                        background:
                                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                    }}
                                                    title={item.title}
                                                    position="top"
                                                    actionIcon={
                                                        <IconButton
                                                            onClick={(e) => updateUsedImages(e, index)}
                                                            sx={{ color: 'white' }}
                                                            aria-label={`use image`}
                                                        >
                                                            {item.active ? <RadioButtonCheckedIcon /> : <PanoramaFishEyeIcon />}

                                                        </IconButton>
                                                    }
                                                    actionPosition="left"
                                                />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>

                                    <h2>Ausgewählte Bilder</h2>

                                    <Box sx={{ pb: 2, clear: 'both'}}>
                                        {image.length > 0  ? <hr/> : <></>}

                                        <ImageList sx={{ width: 500 }} cols={1}>
                                            {image.filter(one => one.active === true).map((item, index) => (
                                                <ImageListItem key={item.img}>
                                                <img
                                                    alt=""
                                                    srcSet={`${item.img}?w=500&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${item.img}?w=500&fit=crop&auto=format`}
                                                    loading="lazy"
                                                />
                                                <ImageListItemBar
                                                    sx={{
                                                        background:
                                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                    }}
                                                    title={item.title}
                                                    position="top"
                                                    actionIcon={
                                                        <>
                                                            <IconButton
                                                                onClick={(e) => updateUsedImages(e, index)}
                                                                sx={{ color: 'white' }}
                                                                aria-label={`use image`}
                                                            >
                                                                {item.active ? <RadioButtonCheckedIcon /> : <PanoramaFishEyeIcon />}
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={(e) => updateUsedImages(e, index)}
                                                                sx={{ color: 'white' }}
                                                                aria-label={`use image`}
                                                            >
                                                                <ArrowCircleUpIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={(e) => updateUsedImages(e, index)}
                                                                sx={{ color: 'white' }}
                                                                aria-label={`use image`}
                                                            >
                                                                <ArrowCircleDownIcon />
                                                            </IconButton>
                                                        </>
                                                    }
                                                    actionPosition="left"
                                                />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <div>
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    {t('post.edit.step.button.continue')}
                                </Button>
                                <Button
                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    {t('post.edit.step.button.back')}
                                </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key="step4">
                        <StepLabel>
                        {t('post.edit.step.campaign.title')}
                        </StepLabel>
                        <StepContent>
                            <Box sx={{ bgcolor: "white", padding: "20px" }}>
                                <Typography>{t('post.edit.step.campaign.description')}</Typography>
                                step.campaign
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {t('post.edit.step.button.continue')}
                                    </Button>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {t('post.edit.step.button.back')}
                                    </Button>
                                    </div>
                                </Box>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key="step5">
                        <StepLabel
                            optional={(<Typography variant="caption">Last step</Typography>)}
                        >
                        {t('post.edit.step.review.title')}
                        </StepLabel>
                        <StepContent>
                            <Box sx={{ bgcolor: "white", padding: "20px" }}>
                                <Typography>{t('post.edit.step.review.description')}</Typography>
                                step.review
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {t('post.edit.step.button.finish')}
                                    </Button>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {t('post.edit.step.button.back')}
                                    </Button>
                                    </div>
                                </Box>
                            </Box>
                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep === maxSteps && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>{t('post.edit.step.hint.reset')}</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        {t('post.edit.step.button.reset')}
                    </Button>
                    </Paper>
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    elevation={0}
                    sx={{ mt: 3, p: 1, mb: 2, borderRadius: "25px", }}
                >
                    {t('post.edit.button.save')}
                </Button>
            </Box>
        </>
    )
}

export default EditPost
