import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Scheduler } from "@aldabil/react-scheduler";
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Paper, Typography } from '@mui/material'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import InstagramIcon from '@mui/icons-material/Instagram';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';


const ListScheduler  = () => {
    const { t, i18n } = useTranslation();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [jobs, setJobs] = useState([]);

    const navigate = useNavigate();

    const fetchRemote = async query => {
        console.log({ query });
        /**Simulate fetchin remote data */
        const { data } = await axios.get(`/api/job/list?start=${query.start.toISOString()}&end=${query.end.toISOString()}`);
        const events = data.jobs.map(one => ({...one, start: new Date(one.start), end: new Date(one.end)}));
        return events;
      };

      const handleConfirm = async (
        event,
        action
      ) => {
        console.log("handleConfirm =", action, event.title);

        /**
         * Make sure to return 4 mandatory fields:
         * event_id: string|number
         * title: string
         * start: Date|string
         * end: Date|string
         * ....extra other fields depend on your custom fields/editor properties
         */
        // Simulate http request: return added/edited event
        return new Promise((res, rej) => {
          if (action === "edit") {
            /** PUT event to remote DB */
          } else if (action === "create") {
            /**POST event to remote DB */
          }

          const isFail = Math.random() > 0.6;
          // Make it slow just for testing
          setTimeout(() => {
            if (isFail) {
              rej("Ops... Faild");
            } else {
              res({
                ...event,
                event_id: event.event_id || Math.random()
              });
            }
          }, 3000);
        });
      };

      const handleDelete = async deletedId => {
        // Simulate http request: return the deleted id
        return new Promise((res, rej) => {
          setTimeout(() => {
            res(deletedId);
          }, 3000);
        });
      };

    const handleBackdropToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };


    const displayProductList = async () => {
        try {
            const { data } = await axios.get('/api/job/list');
            setJobs(data.jobs);
        } catch (error) {
            //console.log(error);
        }
        handleBackdropClose();
    }

    useEffect(() => {
        //displayProductList();
    }, []);

    const translations = {
        navigation: {
            month: "Month",
            week: "Week",
            day: "Day",
            today: "Heute",
            agenda: "Agenda"
        },
        form: {
            addTitle: "Add Event",
            editTitle: "Edit Event",
            confirm: "Confirm",
            delete: "Delete",
            cancel: "Cancel"
        },
        event: {
            title: "Title",
            subtitle: "Subtitle",
            start: "Start",
            end: "End",
            allDay: "All Day"
       },
        validation: {
            required: "Required",
            invalidEmail: "Invalid Email",
            onlyNumbers: "Only Numbers Allowed",
            min: "Minimum {{min}} letters",
            max: "Maximum {{max}} letters"
        },
        moreEvents: "More...",
        noDataToDisplay: "No data to display",
        loading: "Loading..."
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box>
                <Typography variant='h5' sx={{ pb: 4 }}> {t('job.list.title')} </Typography>
            </Box>
            <Scheduler
                getRemoteEvents={fetchRemote}
                onConfirm={handleConfirm}
                onDelete={handleDelete}
                translations={translations}
            />
        </>
    );
}

export default ListScheduler
