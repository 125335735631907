import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify';

import { Box, Button, Paper, Typography } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

const ListAccount = () => {

    const { t, i18n } = useTranslation();
    const [accounts, setAccounts] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(true);

    const navigate = useNavigate();

    const handleBackdropToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };

    const displayAccountList = async () => {
        try {
            const { data } = await axios.get('/api/account/list');
            setAccounts(data.accounts);
        } catch (error) {
            //console.log(error);
        }
        handleBackdropClose();
    }

    useEffect(() => {
        displayAccountList();
    }, [])


    //delete post by Id
    const deleteAccountById = async (e, id) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.delete(`/api/account/delete/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                displayAccountList();
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const validateAccountById = async (e, id) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.get(`/api/account/validate/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                displayAccountList();
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const columns = [

        {
            field: 'name',
            headerName: t('account.list.table.col.name'),
            width: 150,
        },
        {
            field: 'source',
            headerName: t('account.list.table.col.source'),
            width: 100,
        },
        {
            field: 'valid',
            headerName: t('account.list.table.col.valid'),
            width: 150,
            renderCell: (value) => {
                if (value) {
                    return (<VerifiedOutlinedIcon sx={{p: 2}} color="success"/>);
                } else {
                    return (<VerifiedOutlinedIcon sx={{p: 2}} color="error"/>);
                }
            },
        },
        {
            field: "actions",
            headerName: t('account.list.table.col.action'),
            width: 200,
            renderCell: (value) => (
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "70px" }}>
                    <Link to={`/user/account/edit/${value.row._id}`}>
                        <IconButton aria-label="Bearbeite" >
                            <EditIcon />
                        </IconButton>
                    </Link>
                    <IconButton aria-label="Lösche Account" onClick={(e) => deleteAccountById(e, value.row._id)} >
                        <DeleteIcon  />
                    </IconButton>
                    <IconButton aria-label="Validate Account" onClick={(e) => validateAccountById(e, value.row._id)} >
                        <VerifiedIcon />
                    </IconButton>
                </Box>
            )
        }
    ];


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box >
                <Typography variant="h4" sx={{ color: "black", pb: 3 }}>
                    {t('account.list.page.title')}
                </Typography>
                <Box sx={{ pb: 2, display: "flex", justifyContent: "right" }}>
                    <Button variant='contained' color="success" startIcon={<AddIcon />}><Link style={{ color: 'white', textDecoration: 'none' }} to='/user/account/create'> {t('account.list.button.create')}</Link> </Button>
                </Box>
                <Paper sx={{ bgcolor: "white" }} >

                    <Box sx={{ height: 500, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            sx={{

                                '& .MuiTablePagination-displayedRows': {
                                    color: 'black',
                                },
                                color: 'black',
                                [`& .${gridClasses.row}`]: {
                                    bgcolor: "white"
                                },

                            }}
                            rows={accounts}
                            columns={columns}
                            pageSize={3}
                            rowsPerPageOptions={[3]}
                            checkboxSelection
                        />
                    </Box>
                </Paper>
            </Box>
        </>
    )
}

export default ListAccount
