import { Box, Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

const EditAccount = () => {

    const { t, i18n } = useTranslation();

    const validationSchema = yup.object({
        name: yup
            .string('Produktname hinzufügen')
            .min(4, t('account.edit.name.minlength'))
            .required(t('account.edit.name.error')),
        source: yup
            .string('Wählen Sie eine Quelle aus')
            .required(t('account.edit.source.error')),
        userid: yup
            .string('Wählen Sie eine Sprache aus')
            .min(4, t('account.edit.userid.minlength'))
            .required(t('account.edit.userid.error')),
        username: yup
            .string('Wählen Sie eine Sprache aus')
            .min(4, t('account.edit.username.minlength'))
            .required(t('account.edit.username.error')),
        accesstoken: yup
            .string('Artikelnummer hinzufügen')
            .min(4, t('account.edit.accesstoken.minlength'))
            .required(t('account.edit.accesstoken.error')),
    });

    const { id } = useParams();
    const [name, setName] = useState('');
    const [source, setSource] = useState([]);
    const [userid, setUserid] = useState('');
    const [username, setUsername] = useState('');
    const [accesstoken, setAccesstoken] = useState(null);
    const [valid, setValid] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(true);

    const handleBackdropToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };

    const navigate = useNavigate();

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues: {
            name,
            source,
            userid,
            username,
            accesstoken,
            valid
        },

        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, actions) => {
            updateAccount(values);
        },
    });


    //show post by Id
    const getAccount = async () => {
        try {
            const { data } = await axios.get(`/api/account/${id}`);
            setName(data.account.name);
            setSource(data.account.source);
            setUserid(data.account.userid);
            setUsername(data.account.username);
            setAccesstoken(data.account.accesstoken);
            setValid(data.account.valid);
        } catch (error) {
            toast.error(error);
        }
        handleBackdropClose();
    }

    useEffect(() => {
        getAccount();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateAccount = async (values) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.put(`/api/account/update/${id}`, values);
            if (data.success === true) {
                toast.success(t(data.message));
            } else {
                toast.error(t('account.edit.save.error.text'));
            }
        } catch (error) {
            toast.error(t('account.edit.save.error.text'));
        }
        handleBackdropClose();
    }

    const validateAccountById = async () => {
        handleBackdropToggle();
        try {
            const { data } = await axios.get(`/api/account/validate/${id}`);
            if (data.success === true) {
                toast.success(data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ bgcolor: "white", padding: "20px 200px" }}>
                <Typography variant='h5' sx={{ pb: 4 }}> {t('account.edit.page.title')} {valid ? (<VerifiedOutlinedIcon color="success"/>) : (<VerifiedOutlinedIcon color="error"/>)  }  </Typography>
                <Box sx={{ pb: 2, display: "flex", justifyContent: "right" }}>
                    <Button sx={{float: "right", mb:4}} onClick={() => validateAccountById()} variant='contained' color="success"  startIcon={<VerifiedIcon />}>{t('account.edit.button.validate.title')}</Button>
                </Box>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="name"
                        label={t('account.edit.name.label')}
                        name='name'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.edit.name.placeholder')}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                    />

                    <FormControl  sx={{ mb: 3 }} fullWidth>
                        <InputLabel id="source-label">{t('account.edit.source.label')}</InputLabel>
                        <Select
                            id="source"
                            name="source"
                            labelId="source-label"
                            value={values.source}
                            label={t('account.edit.source.label')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.source && Boolean(errors.source)}
                        >
                            <MenuItem value={'instagram'}>instagram</MenuItem>
                            <MenuItem value={'tiktok'}>tiktok</MenuItem>
                        </Select>
                    </FormControl>


                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="userid"
                        label={t('account.edit.userid.label')}
                        name='userid'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.edit.userid.placeholder')}
                        value={values.userid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.userid && Boolean(errors.userid)}
                        helperText={touched.userid && errors.userid}
                    />

                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="username"
                        label={t('account.edit.username.label')}
                        name='username'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.edit.username.placeholder')}
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.username && Boolean(errors.username)}
                        helperText={touched.username && errors.username}
                    />

                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="accesstoken"
                        label={t('account.edit.accesstoken.label')}
                        name='accesstoken'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.edit.accesstoken.placeholder')}
                        value={values.accesstoken}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.accesstoken && Boolean(errors.accesstoken)}
                        helperText={touched.accesstoken && errors.accesstoken}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        elevation={0}
                        sx={{ mt: 3, p: 1, mb: 2, borderRadius: "25px", }}
                    // disabled={loading}
                    >
                        {t('account.edit.button.title')}
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default EditAccount
