import { Box, Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';
import Rating from '@mui/material/Rating';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';

const EditProduct = () => {

    const { t, i18n } = useTranslation();

    const validationSchema = yup.object({
        source: yup
            .string('Wählen Sie eine Quelle aus')
            .required(t('product.edit.source.error')),
        language: yup
            .string('Wählen Sie eine Sprache aus')
            .required(t('product.edit.language.error')),
        title: yup
            .string('Produktname hinzufügen')
            .min(4, t('product.edit.title.minlength'))
            .required(t('product.edit.title.error')),
        artnumber: yup
            .string('Artikelnummer hinzufügen')
            .min(4, t('product.edit.artnumber.minlength'))
            .required(t('product.edit.artnumber.error')),
    });

    const { id } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [artnumber, setArtnumber] = useState('');
    const [language, setLanguage] = useState('');
    const [source, setSource] = useState([]);
    const [dataFromSource, setDataFromSource] = useState(null);
    const [openBackdrop, setOpenBackdrop] = useState(true);

    const handleBackdropToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };

    const createPostForProductById = async () => {
        handleBackdropToggle();
        try {
            const { data } = await axios.post(`/api/post/product/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                navigate(`/user/post/edit/${data.post._id}`);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues: {
            title,
            artnumber,
            source,
            language,
            dataFromSource
        },

        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, actions) => {
            updatePost(values);
        },
    });


    //show post by Id
    const getProduct = async () => {
        try {
            const { data } = await axios.get(`/api/product/${id}`);
            setTitle(data.product.title);
            setArtnumber(data.product.artnumber);
            setSource(data.product.source);
            setLanguage(data.product.language);
            if (data.product.data) {
                setDataFromSource(data.product.data);
            }
        } catch (error) {
            toast.error(error);
        }
        handleBackdropClose();
    }

    useEffect(() => {
        getProduct();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updatePost = async (values) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.put(`/api/product/update/${id}`, values);
            if (data.success === true) {
                toast.success(t('product.edit.save.success.text'));
            } else {
                toast.error(t('product.edit.save.error.text'));
            }
        } catch (error) {
            toast.error(t('product.edit.save.error.text'));
        }
        handleBackdropClose();
    }

    const retrieveContent = async () =>  {
        handleBackdropToggle();
        try {
            const { data } = await axios.get(`/api/product/fetch/${id}`);

            if (data.success === true && data.productUpdate.data) {
                toast.success(t('product.edit.retrieve.success.text'));
                setDataFromSource(data.productUpdate.data);
            } else {
                toast.error(t('product.edit.retrieve.error.text'));
            }
        } catch (error) {
            toast.error(t('product.edit.retrieve.error.text'));
        }
        handleBackdropClose();
    }


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        <Box sx={{ bgcolor: "white", padding: "20px 200px" }}>
            <Typography variant='h5' sx={{ pb: 4 }}> {t('product.edit.page.title')}  </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField sx={{ mb: 3 }}
                fullWidth
                id="title"
                label={t('product.edit.title.label')}
                name='title'
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder={t('product.edit.title.placeholder')}
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
            />

            <TextField sx={{ mb: 3 }}
                fullWidth
                id="artnumber"
                label={t('product.edit.artnumber.label')}
                name='artnumber'
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder={t('product.edit.artnumber.placeholder')}
                value={values.artnumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.artnumber && Boolean(errors.artnumber)}
                helperText={touched.artnumber && errors.artnumber}
            />

            <FormControl  sx={{ mb: 3 }} fullWidth>
                <InputLabel id="source-label">{t('product.edit.source.label')}</InputLabel>
                <Select
                    id="source"
                    name="source"
                    value={values.source}
                    labelId="source-label"
                    label={t('product.edit.source.label')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.source && Boolean(errors.source)}
                >
                    <MenuItem value={'amazon.de'}>amazon.de</MenuItem>
                    <MenuItem value={'ebay.de'}>ebay.de</MenuItem>
                    <MenuItem value={'custome'}>eigener Shop</MenuItem>
                </Select>
            </FormControl>

            <FormControl  sx={{ mb: 3 }} fullWidth>
                <InputLabel id="language-label">{t('product.edit.language.label')}</InputLabel>
                <Select
                    id="language"
                    name="language"
                    labelId="language-label"
                    value={values.language}
                    label={t('product.edit.language.label')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.language && Boolean(errors.language)}
                >
                    <MenuItem value={'de-DE'}>de-DE</MenuItem>
                    <MenuItem value={'en-US'}>en-US</MenuItem>
                </Select>
            </FormControl>


            <hr/>

            <Box sx={{ pt:2, pb: 2, clear: 'both'}}>
                <Button sx={{float: "right", mb:4}} onClick={() => retrieveContent()} variant='contained' color="success"  startIcon={<SystemUpdateAltIcon />}>{t('product.content.button.title')}</Button>
                {dataFromSource ? <>
                    <h1>{dataFromSource.title}</h1>
                    {dataFromSource.bullets && dataFromSource.bullets.length > 0 ?
                        <ul>
                        {dataFromSource.bullets.map((one) => (<li>{one}</li>))}
                        </ul>
                    : <></>}

                    <h3>{t('product.link.label')}</h3>
                        <a href={dataFromSource.link} >{dataFromSource.link}</a>
                    <hr/>
                    <h3>{t('product.price.label')}</h3>
                        {dataFromSource.price} {dataFromSource.currency}
                    <hr/>
                    <h3>{t('product.review.label')}</h3>
                        <Rating name="read-only" value={dataFromSource.stars} readOnly /> {dataFromSource.stars} ({dataFromSource.reviews})
                    <hr/>
                    <h3>{t('product.image.label')}</h3>
                    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                        {dataFromSource.images.map((item) => (
                            <ImageListItem key={item}>
                            <img
                                src={`${item}`}
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    </> : <></>}
                </Box>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    elevation={0}
                    sx={{ mt: 3, p: 1, mb: 2, borderRadius: "25px", }}
                // disabled={loading}
                >
                {t('product.edit.button.title')}
                </Button>

                <Button
                    variant="contained"
                    fullWidth
                    elevation={0}
                    color="success"
                    sx={{ mt: 3, p: 1, mb: 2, borderRadius: "25px", }}
                    startIcon={<PostAddIcon />} onClick={() => createPostForProductById()} >
                        {t('product.edit.button.create.post.title')}
                </Button>
            </Box>
        </Box>
        </>
    )
}

export default EditProduct
