import { Box, Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import axios from 'axios';
import { toast } from 'react-toastify'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useNavigate } from 'react-router-dom';


const CreateAccount = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object({
        name: yup
            .string('Produktname hinzufügen')
            .min(4, t('account.create.name.minlength'))
            .required(t('account.create.name.error')),
        source: yup
            .string('Wählen Sie eine Quelle aus')
            .required(t('account.create.source.error')),
        userid: yup
            .string('Wählen Sie eine Sprache aus')
            .min(4, t('account.create.userid.minlength'))
            .required(t('account.create.userid.error')),
        username: yup
            .string('Wählen Sie eine Sprache aus')
            .min(4, t('account.create.username.minlength'))
            .required(t('account.create.username.error')),
        accesstoken: yup
            .string('Artikelnummer hinzufügen')
            .min(4, t('account.create.accesstoken.minlength'))
            .required(t('account.create.accesstoken.error')),
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues: {
            name: '',
            userid: '',
            username: '',
            source: '',
            accesstoken: ''
        },

        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            createNewAccount(values);
        },
    });

    const createNewAccount = async (values) => {
        try {
            const { data } = await axios.post('/api/account/create', values);
            if (data.success) {
                toast.success(t(data.message));
                navigate(`/user/account/edit/${data.account._id}`);
            } else {
                toast.error(t(data.message));
            }
        } catch (error) {
            toast.error(error);
        }
    }


    return (
        <>
            <Box sx={{ bgcolor: "white", padding: "20px 200px" }}>
                <Typography variant='h5' sx={{ pb: 4 }}> {t('account.create.page.title')}  </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="name"
                        label={t('account.create.name.label')}
                        name='name'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.create.name.placeholder')}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                    />

                    <FormControl  sx={{ mb: 3 }} fullWidth>
                        <InputLabel id="source-label">{t('account.create.source.label')}</InputLabel>
                        <Select
                            id="source"
                            name="source"
                            labelId="source-label"
                            value={values.source}
                            label={t('account.create.source.label')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.source && Boolean(errors.source)}
                        >
                            <MenuItem value={'instagram'}>instagram</MenuItem>
                            <MenuItem value={'tiktok'}>tiktok</MenuItem>
                        </Select>
                    </FormControl>


                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="userid"
                        label={t('account.create.userid.label')}
                        name='userid'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.create.userid.placeholder')}
                        value={values.userid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.userid && Boolean(errors.userid)}
                        helperText={touched.userid && errors.userid}
                    />

                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="username"
                        label={t('account.create.username.label')}
                        name='username'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.create.username.placeholder')}
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.username && Boolean(errors.username)}
                        helperText={touched.username && errors.username}
                    />

                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="accesstoken"
                        label={t('account.create.accesstoken.label')}
                        name='accesstoken'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('account.create.accesstoken.placeholder')}
                        value={values.accesstoken}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.accesstoken && Boolean(errors.accesstoken)}
                        helperText={touched.accesstoken && errors.accesstoken}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        elevation={0}
                        sx={{ mt: 3, p: 1, mb: 2, borderRadius: "25px", }}
                    >
                        {t('account.create.button.title')}
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default CreateAccount
