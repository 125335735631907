import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Sidebar, Menu, MenuItem, menuClasses } from 'react-pro-sidebar';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import InventoryIcon from '@mui/icons-material/Inventory';
import LoginIcon from '@mui/icons-material/Login';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { userLogoutAction, userProfileAction } from '../redux/actions/userAction';

const SidebarAdm = () => {

    const { t, i18n } = useTranslation();
    const { userInfo } = useSelector(state => state.signIn);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(userProfileAction());
    }, []);

    //log out
    const logOut = () => {
        dispatch(userLogoutAction());
        window.location.reload(true);
        setTimeout(() => {
            navigate('/');
        }, 500)
    }


    return (
        <>
            <Sidebar backgroundColor="white" style={{ borderRightStyle: "none" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                    <Box sx={{ pt: 4 }}>

                        <Menu
                            menuItemStyles={{

                                button: {
                                    [`&.${menuClasses.button}`]: {
                                        color: "#000",
                                    },
                                    [`&.${menuClasses.disabled}`]: {
                                        color: "green",
                                    },
                                    '&:hover': {
                                        backgroundColor: "#fafafa",
                                        color: "#E64A19",
                                    },
                                },

                                icon: {
                                    [`&.${menuClasses.icon}`]: {
                                        color: '#E64A19',
                                    }
                                },
                            }}

                        >
                            {
                                userInfo && userInfo.role === 'admin' ?
                                    <>
                                        <MenuItem component={<Link to="/admin/dashboard" />} icon={<HomeIcon />}> {t('sidebar.dashboard.title')} </MenuItem>
                                        <MenuItem component={<Link to="/admin/post/list" />} icon={<CampaignIcon />}> {t('sidebar.post.title')} </MenuItem>
                                    </> :
                                    <>
                                        <MenuItem component={<Link to="/user/dashboard" />} icon={<HomeIcon />}> {t('sidebar.dashboard.title')} </MenuItem>
                                        <MenuItem component={<Link to="/user/product/list" />} icon={<InventoryIcon />}> {t('sidebar.product.title')}</MenuItem>
                                        <MenuItem component={<Link to="/user/post/list" />} icon={<CampaignIcon />}> {t('sidebar.post.title')}</MenuItem>
                                        <MenuItem component={<Link to="/user/account/list" />} icon={<RecordVoiceOverIcon />}> {t('sidebar.account.title')}</MenuItem>
                                        <MenuItem component={<Link to="/user/scheduler/list" />} icon={<CalendarMonthIcon />}> {t('sidebar.scheduler.title')}</MenuItem>
                                    </>
                            }

                        </Menu>
                    </Box>
                </Box>
            </Sidebar>
        </>
    )
}

export default SidebarAdm
