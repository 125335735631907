import Chart from "react-apexcharts";
import { Box } from "@mui/system";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

// series data for radar
const SERIES_DATA = [
  {
    name: "Series 1",
    data: [80, 50, 30, 40, 100, 20],
  },
  {
    name: "Series 2",
    data: [20, 30, 40, 80, 20, 80],
  },
  {
    name: "Series 3",
    data: [44, 76, 78, 13, 43, 10],
  },
];

const ChartCurrentSubject = () => {
  const chartOptions = {
    chart: { toolbar: { show: false } },
    stroke: { width: 2 },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    markers: { size: 0 },
    xaxis: {
      categories: [
        "English",
        "History",
        "Physics",
        "Geography",
        "Chinese",
        "Math",
      ],
    },
    fill: {
      opacity: 0.5,
    },
  };

  return (
    <Card>
      <CardHeader title="Current Subject" />
        <Box>
            <Chart
            options={chartOptions}
            series={SERIES_DATA}
            type="radar"
            height={350}
            />
        </Box>
    </Card>
  );
};

export default ChartCurrentSubject;
