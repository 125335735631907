import React from 'react'

const NotFound = () => {
    return (
        <>
            <h2>Not Found</h2>
        </>
    )
}

export default NotFound