import { Box, Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';

import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


const CreatePost = () => {

    const { productId } = useParams();
    const [product, setProduct] = useState('');
    const [productObj, setProductObj] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const validationSchema = yup.object({
        title: yup
            .string('Add a post title')
            .min(4, 'text content should havea minimum of 4 characters ')
            .required('Post title is required'),
        content: yup
            .string('Add text content')
            .min(10, 'text content should havea minimum of 10 characters ')
            .required('text content is required'),
    });
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues: {
            title: '',
            content: '',
            image: null,
        },

        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            createNewPost(values);
            //alert(JSON.stringify(values, null, 2));
            actions.resetForm();
        },
    });

    //show post by Id
    const getProduct = async () => {

        try {
            if (productId) {
                const { data } = await axios.get(`/api/product/${productId}`);
                values.product = data.product._id;
                values.productName = data.product.title;
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    }

    useEffect(() => {
        getProduct();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    const createNewPost = async (values) => {
        try {
            const { data } = await axios.post('/api/post/create', values);
            //toast.success(t('product.create.post'));
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    }


    return (
        <>
            <Box sx={{ bgcolor: "white", padding: "20px 200px" }}>
                <Typography variant='h5' sx={{ pb: 4 }}> Beitrag erstellen  </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    {values.product ?
                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        disabled={true}
                        id="product"
                        label={values.productName}
                        name='product'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder="Produkt"
                        value={values.product}
                    /> : <>
                    </>}

                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="title"
                        label="Post title"
                        name='title'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder="Post title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                    />


                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        elevation={0}
                        sx={{ mt: 3, p: 1, mb: 2, borderRadius: "25px", }}
                    // disabled={loading}
                    >
                        Erzeuge Beitrag
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default CreatePost
