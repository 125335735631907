import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';

import { useProSidebar } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { userLogoutAction } from '../redux/actions/userAction';

const HeaderTop = () => {

    const { t, i18n } = useTranslation();
    const { collapseSidebar } = useProSidebar();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.userProfile);

    const logOut = () => {
        dispatch(userLogoutAction());
        window.location.reload(true);
        setTimeout(() => {
            navigate('/');
        }, 500)
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ boxShadow: 0, bgcolor: "#fafafa" }}>
                <Toolbar>
                    <IconButton onClick={() => collapseSidebar()}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2, color: '#E64A19' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {user && user.group ?
                        <Typography variant="h6" component="div" sx={{ color: 'primary', flexGrow: 1 }}>
                        {user.group.name}
                    </Typography>
                        : <></>}
                    <IconButton onClick={logOut}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2, color: '#E64A19' }}
                    >
                        <LoginIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default HeaderTop;
