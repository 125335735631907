import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper, Typography } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ListPost = () => {

    const { t, i18n } = useTranslation();
    const [posts, setPosts] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(true);

    const handleBackdropToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };

    const displayPost = async () => {
        try {
            const { data } = await axios.get('/api/post/list');
            setPosts(data.posts);
        } catch (error) {
            //console.log(error);
        }
        handleBackdropClose();
    }

    useEffect(() => {
        displayPost();
    }, [])


    //delete post by Id
    const deletePostById = async (e, id) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.delete(`/api/post/delete/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                displayPost();
            }
        } catch (error) {
            toast.error(error);
        }
        handleBackdropClose();
    }

    const columns = [
        {
            field: 'title',
            headerName: t('post.list.table.col.title'),
            width: 250,
        },
        {
            field: 'product',
            headerName: t('post.list.table.col.product'),
            width: 150,
            renderCell: (params) => {
                if (params.row.product.title) return (params.row.product.title)
            }
        },
        {
            field: 'createdAt',
            headerName: t('post.list.table.col.createdAt'),
            width: 150,
            renderCell: (params) => (
                moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS')
            )
        },
        {
            field: "Aktionen",
            headerName: t('post.list.table.col.action'),
            width: 100,
            renderCell: (value) => (
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "70px" }}>
                <Link to={`/user/post/edit/${value.row._id}`}>
                    <IconButton aria-label="edit" >
                        <EditIcon />
                    </IconButton>
                </Link>
                <IconButton aria-label="delete" onClick={(e) => deletePostById(e, value.row._id)} >
                    <DeleteIcon />
                </IconButton>
                </Box>
            )
        }
    ];


    return (
        <>
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box >
                <Typography variant="h4" sx={{ color: "black", pb: 3 }}>
                    {t('post.list.page.title')}
                </Typography>
                <Box sx={{ pb: 2, display: "flex", justifyContent: "right" }}>
                    <Button variant='contained' color="success" startIcon={<AddIcon />}><Link style={{ color: 'white', textDecoration: 'none' }} to='/user/post/create'> {t('post.list.button.create')}</Link> </Button>
                </Box>
                <Paper sx={{ bgcolor: "white" }} >

                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            sx={{

                                '& .MuiTablePagination-displayedRows': {
                                    color: 'black',
                                },
                                color: 'black',
                                [`& .${gridClasses.row}`]: {
                                    bgcolor: "white"
                                },

                            }}
                            rows={posts}
                            columns={columns}
                            pageSize={3}
                            rowsPerPageOptions={[3]}
                            checkboxSelection
                        />
                    </Box>
                </Paper>

            </Box>
        </>
    )
}

export default ListPost
