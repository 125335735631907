import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import NotFound from './pages/NotFound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider } from 'react-redux';
import store from './redux/store';

// components
import AdminRoute from './components/AdminRoute';
import UserRoute from './components/UserRoute';

// global
import Layout from './global/Layout'

// admin
import AdminDashboard from './admin/AdminDashboard';
import AdminCreatePost from './admin/CreatePost';
import AdminEditPost from './admin/EditPost';

// user
import EditPost from './user/EditPost';
import ListPost from './user/ListPost';
import CreatePost from './user/CreatePost';

import UserDashboard from './user/UserDashboard';
import ListScheduler from './user/ListScheduler';

import EditProduct from './user/EditProduct';
import ListProduct from './user/ListProduct';
import CreateProduct from './user/CreateProduct';

import EditAccount from './user/EditAccount';
import ListAccount from './user/ListAccount';
import CreateAccount from './user/CreateAccount';

// pages
import LogIn from './pages/LogIn';
import Register from './pages/Register';

//AdminHOC
const AdminDashboardHOC = Layout(AdminDashboard);
const AdminCreatePostHOC = Layout(AdminCreatePost);
const AdminEditPostHOC = Layout(AdminEditPost);

const EditPostHOC = Layout(EditPost);
const ListPostHOC = Layout(ListPost);
const CreatePostHOC = Layout(CreatePost);

const EditProductHOC = Layout(EditProduct);
const ListProductHOC = Layout(ListProduct);
const CreateProductHOC = Layout(CreateProduct);

const EditAccountHOC = Layout(EditAccount);
const ListAccountHOC = Layout(ListAccount);
const CreateAccountHOC = Layout(CreateAccount);

const UserDashboardHOC = Layout(UserDashboard);
const ListSchedulerHOC = Layout(ListScheduler);

const App = () => {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <ProSidebarProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<LogIn />} />
              <Route path='/login' element={<LogIn />} />
              <Route path='/register' element={<Register />} />
              <Route path='*' element={<NotFound />} />

              <Route path='/admin/dashboard' element={<AdminRoute><AdminDashboardHOC /></AdminRoute>} />
              <Route path='/admin/post/create' element={<AdminRoute><AdminCreatePostHOC /></AdminRoute>} />
              <Route path='/admin/post/list' element={<AdminRoute><ListPostHOC /></AdminRoute>} />
              <Route path='/admin/product/list' element={<AdminRoute><AdminCreatePostHOC /></AdminRoute>} />
              <Route path='/admin/post/edit/:id' element={<AdminRoute><AdminEditPostHOC /></AdminRoute>} />

              <Route path='/user/dashboard' element={<UserRoute><UserDashboardHOC /></UserRoute>} />
              <Route path='/user/product/edit/:id' element={<UserRoute><EditProductHOC /></UserRoute>} />
              <Route path='/user/product/create' element={<UserRoute><CreateProductHOC /></UserRoute>} />
              <Route path='/user/product/list' element={<UserRoute><ListProductHOC /></UserRoute>} />
              <Route path='/user/post/edit/:id' element={<UserRoute><EditPostHOC /></UserRoute>} />
              <Route path='/user/post/create' element={<UserRoute><CreatePostHOC /></UserRoute>} />
              <Route path='/user/post/list' element={<UserRoute><ListPostHOC /></UserRoute>} />
              <Route path='/user/account/edit/:id' element={<UserRoute><EditAccountHOC /></UserRoute>} />
              <Route path='/user/account/create' element={<UserRoute><CreateAccountHOC /></UserRoute>} />
              <Route path='/user/account/list' element={<UserRoute><ListAccountHOC /></UserRoute>} />
              <Route path='/user/scheduler/list' element={<UserRoute><ListSchedulerHOC /></UserRoute>} />
            </Routes>
          </BrowserRouter>
        </ProSidebarProvider>

      </Provider>
    </>
  )
}

export default App
