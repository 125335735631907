import { Box, Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useEffect, useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify'

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useNavigate } from 'react-router-dom';


const CreateProduct = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object({
        source: yup
            .string('Wählen Sie eine Quelle aus')
            .required(t('product.create.source.error')),
        language: yup
            .string('Wählen Sie eine Sprache aus')
            .required(t('product.create.language.error')),
        title: yup
            .string('Produktname hinzufügen')
            .min(4, t('product.create.title.minlength'))
            .required(t('product.create.title.error')),
        artnumber: yup
            .string('Artikelnummer hinzufügen')
            .min(4, t('product.create.artnumber.minlength'))
            .required(t('product.create.artnumber.error')),
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues: {
            title: '',
            artnumber: '',
            source: '',
            language: ''
        },

        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            createNewProduct(values);
        },
    });

    const createNewProduct = async (values) => {
        try {
            const { data } = await axios.post('/api/product/create', values);
            if (data.success) {
                toast.success(t(data.message));
                navigate(`/user/product/edit/${data.product._id}`);
            } else {
                toast.error(t(data.message));
            }
        } catch (error) {
            toast.error(error);
        }
    }

    return (
        <>
            <Box sx={{ bgcolor: "white", padding: "20px 200px" }}>
                <Typography variant='h5' sx={{ pb: 4 }}> {t('product.create.page.title')}  </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="title"
                        label={t('product.create.title.label')}
                        name='title'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('product.create.title.placeholder')}
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                    />

                    <TextField sx={{ mb: 3 }}
                        fullWidth
                        id="artnumber"
                        label={t('product.create.artnumber.label')}
                        name='artnumber'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={t('product.create.artnumber.placeholder')}
                        value={values.artnumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.artnumber && Boolean(errors.artnumber)}
                        helperText={touched.artnumber && errors.artnumber}
                    />

                    <FormControl  sx={{ mb: 3 }} fullWidth>
                        <InputLabel id="source-label">{t('product.create.source.label')}</InputLabel>
                        <Select
                            id="source"
                            name="source"
                            labelId="source-label"
                            value={values.source}
                            label={t('product.create.source.label')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.source && Boolean(errors.source)}
                        >
                            <MenuItem value={'amazon.de'}>amazon.de</MenuItem>
                            <MenuItem value={'ebay.de'}>ebay.de</MenuItem>
                            <MenuItem value={'custome'}>eigener Shop</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ mb: 3 }} fullWidth>
                        <InputLabel id="language-label">{t('product.create.language.label')}</InputLabel>
                        <Select
                            id="language"
                            name="language"
                            labelId="language-label"
                            value={values.language}
                            label={t('product.create.language.label')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.language && Boolean(errors.language)}
                        >
                            <MenuItem value={'de-DE'}>de-DE</MenuItem>
                            <MenuItem value={'en-US'}>en-US</MenuItem>
                        </Select>
                    </FormControl>


                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        elevation={0}
                        sx={{ mt: 3, p: 1, mb: 2, borderRadius: "25px", }}
                    >
                        {t('product.create.button.title')}
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default CreateProduct
