import React from 'react'
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import ChartSiteVisits from '../components/Dashboard/ChartSiteVisits.js';
import ChartCurrentSubject from '../components/Dashboard/ChartCurrentSubject.js';
import ConversionRate from '../components/Dashboard/ConversionRate.js';

const UserDashboard = () => {

    const { user } = useSelector(state => state.userProfile);

    return (
        <>
            <Box sx={{ bgcolor: "white", p: 3 }}>
                <ChartCurrentSubject />
                <ChartSiteVisits />
                <ConversionRate />
            </Box>
        </>
    )
}

export default UserDashboard
