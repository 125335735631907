import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Avatar, Box } from '@mui/material'
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import LockOpenIcon from '@mui/icons-material/LockOpen';

import { userSignUpAction } from '../redux/actions/userAction'
import Navbar from '../components/Navbar';
import { toast } from 'react-toastify';

const Register = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object({
        name: yup
            .string('Geben Sie einen Namen an')
            .required(t('register.form.name.error')),
        companyname: yup
            .string('Geben Sie einen Firmennamen an')
            .required(t('register.form.companyname.error')),
        email: yup
            .string('Geben Sie Ihre E-Mail Adresse an')
            .email(t('register.form.email.valid'))
            .required(t('register.form.email.error')),
        password: yup
            .string('Geben Sie ein Passwort ein')
            .min(8, t('register.form.password.minlength'))
            .required(t('register.form.password.error')),
        password2: yup
            .string('Geben Sie ein Password ein')
            .min(8, t('register.form.password2.minlength'))
            .required(t('register.form.password2.error')),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            companyname: '',
            email: '',
            password: '',
            password2: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            if (values.password === values.password2) {
                dispatch(userSignUpAction(values));
                navigate('/login');
            } else {
                toast.error(t('register.form.password.notequal.error'));
            }
        }

    })

    return (
        <>
            <Navbar />
            <Box sx={{ height: '81vh', display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "primary.white" }}>


                <Box onSubmit={formik.handleSubmit} component="form" className='form_style border-style' >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
                            <LockOpenIcon />
                        </Avatar>
                        <TextField
                            sx={{
                                mb: 3,
                                "& .MuiInputBase-root": {
                                    color: 'text.secondary',
                                },
                                fieldset: { borderColor: "rgb(231, 235, 240)" }
                            }}
                            fullWidth
                            id="name"
                            label={t("register.form.name.label")}
                            name='name'
                            InputLabelProps={{
                                shrink: true,
                            }}

                            placeholder={t("register.form.name.placeholder")}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            sx={{
                                mb: 3,
                                "& .MuiInputBase-root": {
                                    color: 'text.secondary',
                                },
                                fieldset: { borderColor: "rgb(231, 235, 240)" }
                            }}
                            fullWidth
                            id="companyname"
                            label={t("register.form.companyname.label")}
                            name='companyname'
                            InputLabelProps={{
                                shrink: true,
                            }}

                            placeholder={t("register.form.companyname.placeholder")}
                            value={formik.values.companyname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.companyname && Boolean(formik.errors.companyname)}
                            helperText={formik.touched.companyname && formik.errors.companyname}
                        />
                        <TextField
                            sx={{
                                mb: 3,
                                "& .MuiInputBase-root": {
                                    color: 'text.secondary',
                                },
                                fieldset: { borderColor: "rgb(231, 235, 240)" }
                            }}
                            fullWidth
                            id="email"
                            label={t("register.form.email.label")}
                            name='email'
                            InputLabelProps={{
                                shrink: true,
                            }}

                            placeholder={t("register.form.email.placeholder")}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            sx={{
                                mb: 3,
                                "& .MuiInputBase-root": {
                                    color: 'text.secondary'
                                },
                                fieldset: { borderColor: "rgb(231, 235, 240)" }
                            }}
                            fullWidth
                            id="password"
                            name="password"
                            label={t("register.form.password.label")}
                            type="password"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={t("register.form.password.placeholder")}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <TextField
                            sx={{
                                mb: 3,
                                "& .MuiInputBase-root": {
                                    color: 'text.secondary'
                                },
                                fieldset: { borderColor: "rgb(231, 235, 240)" }
                            }}
                            fullWidth
                            id="password2"
                            name="password2"
                            label={t("register.form.password2.label")}
                            type="password2"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={t("register.form.password2.placeholder")}
                            value={formik.values.password2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password2 && Boolean(formik.errors.password2)}
                            helperText={formik.touched.password2 && formik.errors.password2}
                        />

                        <Button fullWidth variant="contained" type='submit' >{t("register.button.title")}</Button>
                        <Box sx={{ mt: 2, display: "flex", alignItems: "left", width: "100%" }}>
                            <Link sx={{mr:2}} href="/login" underline="none">
                                {t('register.login.title')}
                            </Link>
                            <Link href="/password" underline="none">
                                {t('register.password.title')}
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Register
