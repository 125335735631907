import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify';

import { Box, Button, Paper, Typography } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';

import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import AddIcon from '@mui/icons-material/Add';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ListProduct = () => {

    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(true);

    const navigate = useNavigate();

    const handleBackdropToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };

    const displayProductList = async () => {
        try {
            const { data } = await axios.get('/api/product/list');
            setProducts(data.products);
        } catch (error) {
            //console.log(error);
        }
        handleBackdropClose();
    }

    useEffect(() => {
        displayProductList();
    }, [])


    //delete post by Id
    const deletePostById = async (e, id) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.delete(`/api/post/delete/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                displayProductList();
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const retrieveData = async (e, id) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.get(`/api/product/fetch/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                displayProductList();
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const createPostForProductById = async (e, id) => {
        handleBackdropToggle();
        try {
            const { data } = await axios.post(`/api/post/product/${id}`);
            if (data.success === true) {
                toast.success(data.message);
                navigate(`/user/post/edit/${data.post._id}`);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        handleBackdropClose();
    }

    const columns = [

        {
            field: 'title',
            headerName: t('product.list.table.col.title'),
            width: 150,
        },
        {
            field: 'source',
            headerName: t('product.list.table.col.source'),
            width: 100,
        },
        {
            field: 'status',
            headerName: t('product.list.table.col.status'),
            width: 150,
            renderCell: (value) => {
                if (value == 'new') {
                    return t('product.list.table.col.status.new');
                } else {
                    return t('product.list.table.col.status.fetched');
                }
            },
        },
        {
            field: 'artnumber',
            headerName: t('product.list.table.col.artnumber'),
            width: 150,
        },
        {
            field: "actions",
            headerName: t('product.list.table.col.action'),
            width: 200,
            renderCell: (value) => (
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "70px" }}>
                    <Link to={`/user/product/edit/${value.row._id}`}>
                        <IconButton aria-label="Bearbeite" >
                            <EditIcon />
                        </IconButton>
                    </Link>
                    <IconButton aria-label="Lösche Produkt" onClick={(e) => deletePostById(e, value.row._id)} >
                        <DeleteIcon  />
                    </IconButton>
                    <IconButton aria-label="Erzeuge Beitrag" onClick={(e) => createPostForProductById(e, value.row._id)} >
                        <PostAddIcon />
                    </IconButton>
                    <IconButton aria-label="Daten herunterladen" onClick={(e) => retrieveData(e, value.row._id)} >
                        <SystemUpdateAltIcon  />
                    </IconButton>
                </Box>
            )
        }
    ];


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box >
                <Typography variant="h4" sx={{ color: "black", pb: 3 }}>
                    {t('product.list.page.title')}
                </Typography>
                <Box sx={{ pb: 2, display: "flex", justifyContent: "right" }}>
                    <Button sx={{mr: 2}} variant='contained' startIcon={<PlayForWorkIcon />}><Link style={{ color: 'white', textDecoration: 'none' }} to='/user/product/import'> {t('product.list.button.import')}</Link> </Button>
                    <Button variant='contained' color="success" startIcon={<AddIcon />}><Link style={{ color: 'white', textDecoration: 'none' }} to='/user/product/create'> {t('product.list.button.create')}</Link> </Button>
                </Box>
                <Paper sx={{ bgcolor: "white" }} >

                    <Box sx={{ height: 500, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            sx={{

                                '& .MuiTablePagination-displayedRows': {
                                    color: 'black',
                                },
                                color: 'black',
                                [`& .${gridClasses.row}`]: {
                                    bgcolor: "white"
                                },

                            }}
                            rows={products}
                            columns={columns}
                            pageSize={3}
                            rowsPerPageOptions={[3]}
                        />
                    </Box>
                </Paper>
            </Box>
        </>
    )
}

export default ListProduct
