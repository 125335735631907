import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';

import { Avatar, Box } from '@mui/material'
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import LockClockOutlined from '@mui/icons-material/LockClockOutlined'

import * as yup from 'yup';

import { userSignInAction } from '../redux/actions/userAction'
import Navbar from '../components/Navbar';

const LogIn = () => {

    const { t } = useTranslation();

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email')
            .email(t('login.form.email.valid'))
            .required(t('login.form.email.error')),
        password: yup
            .string('Enter your password')
            .min(8, t('login.form.password.minlength'))
            .required(t('login.form.password.error')),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, userInfo } = useSelector(state => state.signIn);

    useEffect(() => {

        if (isAuthenticated) {
            navigate(`/${userInfo.role}/dashboard`);
        }

    }, [isAuthenticated])

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            dispatch(userSignInAction(values));
        }
    })

    return (
        <>
            <Navbar />
            <Box sx={{ height: '81vh', display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "primary.white" }}>
                <Box onSubmit={formik.handleSubmit} component="form" className='form_style border-style' >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
                            <LockClockOutlined />
                        </Avatar>
                        <TextField
                            sx={{
                                mb: 3,
                                "& .MuiInputBase-root": {
                                    color: 'text.secondary',
                                },
                                fieldset: { borderColor: "rgb(231, 235, 240)" }
                            }}
                            fullWidth
                            id="email"
                            label={t("login.form.email.label")}
                            name='email'
                            InputLabelProps={{
                                shrink: true,
                            }}

                            placeholder={t("login.form.email.placeholder")}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            sx={{
                                mb: 3,
                                "& .MuiInputBase-root": {
                                    color: 'text.secondary'
                                },
                                fieldset: { borderColor: "rgb(231, 235, 240)" }
                            }}
                            fullWidth
                            id="password"
                            name="password"
                            label={t("login.form.password.label")}
                            type="password"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={t("login.form.password.placeholder")}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />

                        <Button fullWidth variant="contained" type='submit' >{t("login.button.title")}</Button>
                        <Box sx={{ mt: 2, display: "flex", alignItems: "left", width: "100%" }}>
                            <Link sx={{mr:2}} href="/register" underline="none">
                                {t('login.register.title')}
                            </Link>
                            <Link href="/password" underline="none">
                                {t('login.password.title')}
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default LogIn
